@import url(../../index.css);

.modal-dialog {
    background-color: rgba(0,0,0,.5);
    position: fixed;
    top: 0;

    width: 100vw;
    height: 100vh;

    border: none;
    z-index: 50;
}

.modal-body {
    z-index: 55;
    margin: auto;
    margin-top: 5%;
}

.close-btn-container {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
}

.close-btn-modal {
    cursor: pointer;
    opacity: 50%;
}

.close-btn-modal:hover {
    opacity: 100%;
}

.preview-banner {
    width: 95%;
    cursor: pointer;
    aspect-ratio: 19/7;
    object-fit: cover;
    border: 3px solid var(--mint);
}

.preview-banner:hover {
    filter: brightness(90%);
}

.accordion-info {
    width: 100% !important;
    background-color: rgb(236, 236, 236);
    padding: 2px 20px !important;
    border-radius: 5px !important;
}

.form-modal-banner {
    margin-top: 25px;
    margin-bottom: 15px;
    width: 100%;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr; 
    gap: 0px 15px; 
    
}





.report-item-container {
    width: 100%;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    border-bottom: 1px solid black;
}

.report-item-container:hover {
    background-color: rgb(215, 227, 227);
}

.report-item-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.report-item-type {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 7.5px;
    padding-right: 10px;
    border-radius: 50px;
    font-size: 12px;
}

.report-item-type-reseña {
    background-color: #1abc5d;
    color:  #0b4825;
}

.report-item-type-vendedor {
    background-color: #ffc758;
    color:  #5b4720;
}

.report-item-type-publicacion {
    background-color: #c6e22b;
    color:  #515c12;
}


.report-item-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
    -webkit-line-clamp: 1;
}

.report-item-status {
}


.filters-table {
    padding: 5px;
    background-color: var(--blue);
    color: white;
    display: flex;
    justify-content: space-around;
}

.input-filters-table {
    flex-direction: row !important;
    align-items: start !important; 
    gap: 5px;
}

.table-reports {
    table-layout: auto;
    width: 100%;
}

.table-head-field {
    padding: 5px;
    background-color: var(--blue);
    color: white;
}

.table-row-report {
    cursor: pointer;
}

.table-row-report:hover {
    filter: brightness(0.85);
}

.table-field {
    padding: 3px;
    background-color: white;
}

.table-field p {
    text-align: center;
}

.td-center {
    text-align: center;
}

.td-paginator {
    display: flex;
    justify-content: space-between;
}

.table-paginator {
    background-color: white;
    display: flex;
    gap: 10px;
    justify-content: end;
    align-items: center;
}

.section-table-icon-btn {
    display: flex;
    margin-right: 7px;
    gap: 3px;
}

.table-icon-btn {
    border-radius: 100px;
    aspect-ratio: 1/1;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: none;
    cursor: pointer;
    margin: 5px 0px;
}


.table-icon-btn:hover {
    filter: brightness(0.85);
}


.disabled-table-icon-btn {
    cursor: no-drop;
    filter: opacity(50%);
}

.disabled-table-icon-btn:hover {
    filter: brightness(100%);
    filter: opacity(50%);
}

.table-btn-img {
    width: 30px;
}

.prev-img {
    rotate: 90deg;
}

.next-img {
    rotate: 270deg;
}

.vertical-line {
    width: 1.5px;
    background-color: black;
    height: 0.9em
}




.banner-info-container {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    padding: 5px 5px;
    border-radius: 5px;
    
    cursor: pointer;
}

.banner-info-container:hover {
    background-color: azure;
    filter: brightness(90%);
}

.banner-info-img {
    aspect-ratio: 19/7;
    object-fit: cover;
    width: 50%;
}