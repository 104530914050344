@import url(../../index.css);

.select-container {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.select{
    width: 100%;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

@media (max-width: 870px){
    .select {
        padding: 10px 5px;
    }
}
