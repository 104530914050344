@import url(../../index.css);

.steper {
    width: min(70%, 500px);
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 25px;
    margin-bottom: 7px;
    
    border-top: 2px solid black;
}

.step-item {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    
    transform: translateY(-50%);
}

.step-number {
    background-color: var(--gray);
    width: 1.5em;
    height: 1.5em;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 100%;
}

.step-number p {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.step-description {
    margin-top: 5px;
    text-align: center;
    font-size: 12px;
    color: var(--gray);
    line-height: 11.5px;

    position: absolute;
    margin-top: calc(100% + 5px);
}

.current-step-item .step-number{
    background-color: var(--mint);
    font-weight: 700;
}

.current-step-item .step-description{
    color: black;
}

.step-description-current {
    font-weight: bold;
}

@media (max-width: 870px){
    .steper {
        width: 95%;
    }
}
