@import url(../../index.css);

.content-layout {
    background-color: white;
    border-radius: 15px;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    width: min(1250px, 70%);
}

.regular {
    width: min(1250px, 70%);
}

.small {
    width: min(900px, 45%);
}

.large {
    width: min(1600px, 87%);
}

.accordion-content-layout {
    padding: 30px 40px !important;
}

.accordion-content-layout-closed {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
}

.accordion-content-layout-open {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    cursor: pointer;
}

.image-arrow-accordion {
    transition: all .2s ease-out;
}

.icon-rotated {
    transform: rotate(180deg);
}


@media (max-width: 870px){
    .content-layout {
        width: min(1250px, 92%);
        padding: 30px 25px;
    }

    .complete-layout {
        width: min(1250px, 100%);
    }
}