@import url(../../index.css);

.input-container {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.input-element {
    display: flex;
    justify-content: start;
    align-items: start; 
    width: 100%;
}

.input {
    width: 100%;
    border-radius: 5px;
    border: 2px solid black;
    padding: 5px;

    transition: all 0.3s;
    background-color: rgb(250, 250, 250);
}

.input:hover {
    background-color: rgb(233, 233, 233);
}

.input-with-icon{
    padding-left: 35px !important;
}

.icon-input {
    position: absolute;
    width: 27px;
    height: 30px;
    margin-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.required {
    color: var(--red);
}

.input-aux-text {
    font-size: 0.8em;
}


.label-input-file {
    width: 100%;
    border-radius: 5px;
    border: 2px solid black;
    padding: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 5px;
}

.label-input-file-disabled {
    cursor: not-allowed;
    background-color: #efefef;
    border: 2px solid rgb(70, 70, 70);
}

.label-input-file p {
    width: 95%;
    display: block;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.label-input-file-disabled p{
    filter: opacity(50%)
}

.label-input-file-disabled img{
    filter: opacity(50%)
}

.icon-input-file{
    width: 55px;
    height: 55px;
}

.image-input-file {
    width: max(100px, 90%);
}

.label-input-file-vertical {
    flex-direction: column-reverse;
}

.btn-quit-file {
    margin-top: 5px;
    width: 100% !important;
}

@media (max-width: 870px){
    .input {
        padding: 10px 5px 10px 5px;
    }

    .icon-input {
        position: absolute;
        width: 30px;
        height: 40px;
        margin-left: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .input-with-icon{
        padding-left: 35px !important;
    }

    .label-input-file {
        flex-direction: column-reverse;
    }
}

@supports (-webkit-touch-callout: none) {
    .icon-input {
      display: none !important;
    }
  }