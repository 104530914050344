@import url(../../index.css);

.item-navbar {
    height: max(zpx, fit-content);
}

.link-item-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    text-decoration: none;
    font-size: 12px;
    padding: 7.5px;
    color: white;
}

.link-item-navbar:hover {
    text-decoration: underline;
    background-color: var(--mint);
}

.icon-item-navbar {
    width: 40px;
    height: 40px;
    margin: 0px 10px;
}

.horizontal-item-navbar {
    flex-direction: column;
}

@media (max-width: 870px){
    .link-item-navbar {
        font-size: 16px;
        width: 100%;
        height: 100%;
        margin-left: 0px;

        padding: 1.5em;
    }
}
