@import url(../../index.css);

.info-body-create-ann {
    margin-bottom: 25px;
}

.button-body-create-ann {
    padding: 20px 0px 20px 0px;
    gap: 5px;
    margin-bottom: 10px !important;
    font-size: 18px;
}

.button-body-create-ann img {
    width: 75px !important;
}

.text-button-create-ann {
    margin-top: 20px;
}


.layout-poput-warn-create-ann {
    margin-top: 100px;
    margin-bottom: 50px;
}

.head-popup-warn-create-ann {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
    margin-bottom: 35px;
}

.head-popup-warn-create-ann h2 {
    color: var(--mint);
}

.head-create-ann-descr{
    width: 100%;
    text-align: justify;
    margin-top: 30px;
}

.head-popup-warn-create-ann img {
    width: 35px;
}

.p-popup-warn-create-ann {
    color: black;
    width: 100%;
    text-align: justify;
    margin-bottom: 28px;
}


.layout-poput-saber-tipo {
    margin-top: 50px;
    margin-bottom: 50px;
}

.h2-poput-saber-tipo {
    margin-bottom: 25px;
    text-align: center;
}

.item-poput-saber-tipo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;    
}

.item-poput-saber-tipo-line{
    border-bottom: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 10%, rgba(38,38,38,1) 90%, rgba(255,255,255,1) 100%);
}

.item-poput-saber-tipo img {
    width: 75px;
    filter: invert(100%);
}

.item-poput-saber-tipo p{
    width: calc(100% - 90px);
    text-align: justify;
}


.form-create-ann {
    width: min(75%, 700px);
}

.p-nota-create-ann {
    width: 100%;
    text-align: justify;
    margin-bottom: 25px;
}

.p-nota-create-ann span {
    color: var(--red);
}

.buttons-create-ann {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons-create-ann button{
    width: 40% !important;
}

.section-imagenes-article {
    width: 100%;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
    gap: 10px;
    margin-bottom: 15px;
}

.section-imagen-article {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: start;
}

.button-close-imagenes-article {
    filter: opacity(50%);
    position: absolute;
    margin-right: -20px;
    margin-top: -20px;
}

.button-close-imagenes-article:hover {
    filter: opacity(100%);
}

.section-imagenes-article img {
    width: 100%;
}

.section-double-input {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.section-double-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.section-double-input-item {
    width: 48% !important;
}

.section-button-add-contact {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: end;
}

.button-quit-contact {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 5px;
}

@media (max-width: 870px){
    .button-body-create-ann{
        width: 85% !important;
        font-size: 16px;
    }

    .button-body-create-ann img {
        width: 50px !important;
    }

    .form-create-ann {
        width: 95%;
    }

    .buttons-create-ann {
        flex-direction: column;
    }
    
    .buttons-create-ann button{
        width: 100% !important;
    }

    .section-imagenes-article {
        width: 100%;
        display: grid; 
        grid-auto-columns: 1fr; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 10px 10px; 
        grid-template-areas: 
            ". . ."
            ". . ."; 
    }

    .section-double-input {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .section-double-input-item {
        width: 100% !important;
    }

    .section-double-checkbox {
        flex-direction: column;
    }
}