@import url('../../index.css');

.navbar {
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    width: 100%;
    background-color: var(--blue);
    height: 75px;

    position: fixed;
    top: 0px;
    left: 0px;
    
}

.link-navbar {
    display: inline-block;
    height: clamp(75px, 100%, 75px);
    margin-left: min(calc(100vw * 0.035), 30px);
}

.logo-navbar {
    height: 100%;
} 

.navbar-links {
    display: flex;
    list-style: none;
    height: 100%;
}
  
.navbar-links li:last-child {
    margin-right: min(calc(100vw * 0.035), 30px);
}
  
.navbar-links a {
    color: white;
    text-decoration: none;
}

.navbar-toggle {
    display: none;
    cursor: pointer;
    margin-right: min(calc(100vw * 0.035), 30px);
}
  
.navbar-toggle.active {
    display: block;
    
}
  
.burger-line {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 7px 0;
    border-radius: 2px;
}

.burger-close-top{
    transform: translateY(12px);
}

.burger-close-bottom{
    transform: translateY(-12px);
}

.list-items-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px;
    background-color: var(--mint);
    position: absolute;
    right: 0;
    top: 75px;
    list-style: none;
}

.item-navbar-account-menu {
    width: 100%;
}

.item-navbar-account-menu a{
    color: white;
}

.item-navbar-account-menu:last-child{
    margin-top: 30px;
}

.item-navbar-account-menu .link-item-navbar{
    font-size: 15px;
    justify-content: start;
}

.item-navbar-account-menu .link-item-navbar:hover {
    background-color: var(--blue);
}


@media (max-width: 870px) {
    .navbar-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 75px;
        left: 100%;
        width: 100%;
        height: calc(100vh - 75px);
        background-color: rgba(44, 62, 80, 0.92);
        text-align: center;
        z-index: 9;
        overflow-y: auto;
    }

    .navbar-links li:last-child {
        margin-right: 0px;
    }
  
    .navbar-links.active {
        display: flex;
        left: 0;
    }
  
    .navbar-links li {
        margin-right: 0;
    }
  
    .navbar-toggle {
        display: block; 
    }
  }
