@import url(../../index.css);

.content-with-aside {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 20px;
}

.column-content {
    width: min(700px, 50%);
}

.annoucement-item-my {
    width: 100%;
    color: black;
    text-decoration: none;
    background-color: white;
    padding: 7.5px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.annoucement-item-my-main{
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 0;
}

.annoucement-item-my-main-sp {
    width: 10px;
    height: 150px;
    content: '';
}

.annoucement-item-my-main-pub {
    font-size: 13px;
    display: flex;
    align-items: center;
    color: black;
    gap: 4px;
}

.annoucement-item-my-main-exp {
    font-size: 13px;
    margin-left: 25px;
    margin-top: -4px;
}

.annoucement-item-my-main-desc {
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
    -webkit-line-clamp: 2;
    font-size: 12px;
}

.annoucement-item-my-main-icon {
    width: 20px !important;
}

.annoucement-item-my-main img {
    width: 150px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.annoucement-item-my-main .column {
    width: calc(100% - 130px);
    color: black;
}

.annoucement-item-my-main .column h2{
    font-weight: 500;
    font-size: 22px;
    margin-top: 5px;
}

.annoucement-item-my-main .column h3{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    color: var(--blue);
    font-size: 18px;
}

.annoucement-item-my-sec{
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 7px;
    font-size: 20px;
}

.p-mint {
    color: var(--mint);
}

.p-gold {
    color: var(--gold);
}

.p-nivel-ann {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.p-nivel-ann-2 {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    font-weight: 600;
    margin-top: 10px;
    padding: 0px 7.5px;
}

.h2-title-my-ann {
    padding: 0 10px 0 10px;
    margin-bottom: 20px;
    text-align: start;
    width: 100%;
}

.content-cards {
    width: 100%;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 1fr; 
    gap: 15px 15px;   
}

.h2-seller-cont {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 20px;
}

.h2-seller-cont h2 {
    text-align: start;
}

.pic-seller {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-color: var(--blue);
    padding: 2.5px;
}

.annoucement-user {
    width: 100% !important;
}

.section-column-annoucement-seller {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 95%;
}

.section-select-order {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: start;
    margin-bottom: 15px;
}

.container-counter-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.counter-btn {
    background-color: #0000;
    border: none;
    padding: 10px;
    font-size: 15px;
    color: black;
    cursor: pointer;
    transition: all 0s;
}

.counter-btn-active, .counter-btn-active:hover {
    color: var(--mint);
    font-weight: 800;
    text-decoration: underline;
}

.counter-btn:hover {
    text-decoration: underline;
}

.select-order {
    width: 25% !important;
}

@media (max-width: 1000px){
    .column-content{
        width: 100%;
    }

    .content-with-aside {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        gap: 20px;
    }

    .annoucement-item-my {
        width: 95%;
    }

    .h2-title-my-ann {
        text-align: center;
    }

    .content-cards {
        padding: 0 10px 0 10px;
        width: 100%;
        display: grid; 
        grid-auto-columns: 1fr; 
        grid-template-columns: 1fr 1fr; 
        gap: 15px 15px;   
    }

    .section-column-annoucement-seller {
        width: 100%;
    }

    .select-order {
        width: 50% !important;
    }
}