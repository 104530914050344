@import url(../../index.css);

/* COLORS */
.mint {
    border: 2px solid var(--mint) !important;
    background-color: var(--mint) !important;
    color: white !important;
}

.red {
    border: 2px solid var(--red) !important;
    background-color: var(--red) !important;
    color: white !important;
}

.transparent {
    background-color: transparent;
}

/* ICON BUTTON */

.icon-button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
    min-height: 40px;
    max-height: 40px;

    width: 40px;
    min-width: 40px;
    max-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-button-img {
    width: 80%;
    height: 80%;
}

/* BUTTON */
.button {
    cursor: pointer;
    background-color: var(--mint);
    color: white;
    font-weight: 700;

    padding: 7.5px 12px;
    border: 2px solid var(--mint);
    border-radius: 5px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    transition: all 0s ease;
}

.button:hover {
    filter: brightness(0.92);
}

.btn-disabled {
    cursor: not-allowed;
    filter: brightness(0.8);
}

.btn-disabled:hover {
    cursor: not-allowed;
    filter: brightness(0.8);
}

.horizontal {
    flex-direction: row;
}

/* TextButton */
.text-button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    color: var(--mint);
    cursor: pointer;

    transition: all 0s ease;
}

.text-button:hover {
    filter: brightness(0.90);
}

.text-button-disabled {
    cursor: not-allowed;
    filter: brightness(40%);
}

.text-button-disabled:hover {
    filter: brightness(40%);
}
