@import url(../../index.css);

.img-profile-pic {
    background-color: var(--blue);
    width: 200px;
    margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 100%;
    padding: 5px;
} 

.mt-15 {
    margin-top: 15px;
}

.mt-25 {
    margin-top: 25px;
}

.p-with-icon {
    gap: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.p-with-icon-text {
    text-align: center;
    margin-top: -2px;
}

.section-buttons-profile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.btn-profile {
    width: 49.5% !important;
}

@media (max-width: 870px){
    .section-buttons-profile {
        flex-direction: column;
    }

    .btn-profile {
        width: 100% !important;
    }
}