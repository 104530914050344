@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');

:root{
    --blue: #2C3E50;
    --mint: #1ABC9C;
    --red: #FF5E58;
    --gray: #BDC3C7;
    --backgound: #ECF0F1;
    --gold: #DDC80E;
}

* {
    margin: 0;
    padding: 0;

    font-family: "Lexend Deca", sans-serif;
    font-optical-sizing: auto;

    transition: all 0.3s ease;
    box-sizing: border-box;

}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--backgound);
    max-width: 100vw;
}

a {
    color: var(--mint);
    cursor: pointer;
    transition: all 0s ease;
}

a:visited {
    color: var(--mint);
}

a:hover {
    filter: brightness(0.9);
}

/* Generics */
.none {
    display: none;
}

.progress {
    cursor: progress !important;
}

/* Swal */
.swal {
    font-family: "Lexend Deca", sans-serif;
}

.swal2-popup {
    border-radius: 10px !important; 
}

.colored-toast{
    margin-top: 72px;
}

.colored-toast.swal2-icon-success {
    background-color: #1abc58 !important;
}
  
.colored-toast.swal2-icon-error {
    background-color: #FF5E58 !important;
}
  
.colored-toast.swal2-icon-warning {
    background-color: #ffd900 !important;
}
  
.colored-toast.swal2-icon-info {
    background-color: #1ABC9C !important;
}
  
.colored-toast.swal2-icon-question {
    background-color: #BDC3C7 !important;
}
  
.colored-toast .swal2-title {
    color: white;
}
  
.colored-toast .swal2-close {
    color: white;
}
  
.colored-toast .swal2-html-container {
    color: white;
}

/* Scroll bar */
::-webkit-scrollbar {
    width: 5px; 
    height: 5px; 
}

::-webkit-scrollbar-track {
    background: var(--backgound);
    border-radius: 5px; 
}

::-webkit-scrollbar-thumb {
    background: var(--mint);
    border-radius: 5px; 
}

.scroll-container {
    scrollbar-width: thin; 
    scrollbar-color: var(--mint) var(--backgound); 
    width: 5px; 
    height: 5px; 
    border-radius: 5px;
}
