@import url(../../index.css);

.filter {
    width: min(15%, 300px);
    position: sticky;
    top: 85px;
}

.mb-25 {
    margin-bottom: 25px;
}

.filter-mobile {
    display: none;
}

.show-order-by {
    display: flex;
    align-items: start;
    margin-top: 5px;
    width: 100%;
    margin-bottom: 25px;
}

@media (max-width: 1000px){
    .filter {
        display: none;
    }

    .filter-mobile {
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 75px;
        z-index: 4;
        margin-top: -20px;
        padding: 0px 10px;
    }

    .filter-mobile-menu {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .p-show-all-filters{
        width: calc(100% - 50px);

        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .p-not-show-all-filters {
        width: calc(100% - 50px);

        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; 
        -webkit-line-clamp: 1;
    }

    .p-show-all-filters {
        -webkit-line-clamp: 100;
    }

    .show-order-by {
        display: flex;
        width: 100%;
        align-items: end;
        margin-top: -5px;
        margin-bottom: 10px;
    }
}