@import url(../../index.css);

.show-announcement-main {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
}

.show-announcement-imgs {
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    flex: 1; 
    min-height: 100%;
}

.show-announcement-imgs-list {
    margin-top: 5px;
    width: 100%;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    gap: 5px 5px; 
}

.show-announcement-imgs-list img {
    cursor: zoom-in;
}

.show-announcement-imgs img {
    width: 100%;
    cursor: zoom-in;
}

.show-announcement-info {
    margin-left: 25px;
    width: calc(50% - 25px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
}

.show-annoucement-general {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.show-annoucement-general-btns {
    display: flex;
    justify-content: end;
    align-items: end;
    width: 100%;
    gap: 0px;
}

.div-fav-btn {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: -10px;
}

.show-announcement-info h1 {
    font-size: 30px;
    color: var(--blue);
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
    -webkit-line-clamp: 4;
}

.show-announcement-info h2 {
    font-size: 45px;
    margin-top: 40px;
    margin-bottom: 7px;
    font-weight: 500;
}

.show-announcement-info ul {
    list-style: none;
}

.show-announcement-info ul li {
    display: flex;
    align-items: center;
    margin-top: 7.5px;
    gap: 5px;
}

.p-expiration-date {
    font-size: 12px;
    margin-left: 30px;
}

.show-announcement-buttons {
    width: 100%;
    margin-top: 20px;
    bottom: 0px;
}

.information-annoucement {
    width: 100%;
    margin-bottom: 15px;
}

.h2-information-annoucement {
    margin-bottom: 10px;
}

.p-information-annoucement{
    text-align: justify;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.p-information-annoucement.contraido {
    -webkit-line-clamp: 5; 
}

.caracteristicas-annoucement {
    width: 100%;
    margin-top: 15px;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 1fr; 
    gap: 10px 0; 
}

.caracteristica-annoucement {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding-right: 10px;
}

.caracteristica-annoucement-content {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
}

.caracteristica-annoucement-content img {
    width: 30px;
}

.ann-seller-section {
    margin-top: 4px;
    width: 100%;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 0.3fr 1.7fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 0px 20px; 
    grid-template-areas: 
      "foto info"
      "foto btns"; 
}

.ann-seller-section-img {
    grid-area: foto;
    width: 100%;
}

.ann-seller-section-img img {
    width: 100%;
}

.ann-seller-section-info {
    grid-area: info;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: 7.5px;
}

.ann-seller-section-info h3{
    font-size: 20px;
}

.ann-seller-section-info-punt {
    display: flex;
    align-items: center;
    gap: 5px;
}

.ann-seller-section-btns {
    grid-area: btns;
    display: flex;
    align-items: end;
    justify-content: space-between;
}


.form-review {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.section-form-review {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.puntuation {
    display: flex;
    align-items: center;
}

.section-show-reviews {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
}

.section-show-reviews p{
    width: 100%;
    text-align: center;
}

.section-show-review {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    margin-bottom: 20px;
}

.section-show-review-profile-pic {
    min-width: 75px;
    width: 75px;
    max-width: 75px;
}

.section-show-review-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: calc(100% - 75px - 7px - 20px);
    margin-left: 7px;
}

.section-show-review-info p{
    text-align: start;
    
}

.puntuation-review-img {
    width: 20px !important;
}

.puntuation-review-text {
    font-size: 12px !important;
}

.section-show-review-buttons {
    min-width: 20px !important;
    width: 20px !important;
    max-width: 20px !important;

    display: flex;
    flex-direction: column;
}

.section-show-review-button {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-show-review-button img {
    width: 100% !important;
    filter: opacity(75%);
}

.section-show-review-button:hover img {
    filter: opacity(100%);
}

.loading-image {
    filter: opacity(25%);
    cursor: progress;
    animation: loading-image 1.5s linear infinite ;
}

.div-loading-content {
    width: 100%;
    background-color: black;
    min-height: 22px;
    animation: loading-image 2s linear infinite ;
    cursor: progress;
    margin-bottom: 12px;
}

.div-loading-content-small {
    width: 90%;
    background-color: black;
    min-height: 10px;
    animation: loading-image 2s linear infinite ;
    cursor: progress;
    margin-bottom: 7px;
}

.swal-contact-seller-popup {
    width: min(60%, 650px);
}

.contact-seller-popup p {
    text-align: justify;
    width: 90%;
    color: black;
    margin-bottom: 10px;
}

.a-show-contact{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    background-color: var(--mint);
    color: white !important;
    font-weight: 500;
    border-radius: 5px;
    width: 90%;
    margin-top: 5px;
    padding: 10px 30px;
}

.a-show-contact-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.a-show-contact-icon-btn {
    filter: opacity(70%);
    width: 9% m !important;
}

.a-show-contact-icon-btn:hover {
    filter: opacity(100%);
}

.a-show-contact-icon-btn img {
    width: 25px;
}

.em-color {
    background-color: var(--red);
}

.wa-color {
    background-color: #0EC043;
}

.ph-color {
    background-color: var(--mint);
}

.fb-color {
    background-color: #1875F9;
}

.tw-color {
    background-color: black;
}

.a-show-contact-button {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    background-color: var(--mint);
    color: white !important;
    font-weight: 500;
    border-radius: 5px;
    width: 90%;
    margin-top: 5px;
    padding: 10px 30px;

    cursor: pointer;
}

.a-show-contact-button:hover {
    filter: brightness(0.9);
}

.a-show-contact img, .a-show-contact-button img {
    width: 25px;
    filter: invert(100%);
}

.contact-seller-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact-seller-popup h2 {
    color: var(--mint);
    margin-top: 20px;
    margin-bottom: 35px;
}

@keyframes loading-image {
    0% {
        filter: opacity(38%);
    }50% {
        filter: opacity(25%);
    }100% {
        filter: opacity(38%);
    }
}

.icon-button-create-ann, .div-fav-btn::before {
    filter: opacity(70%) !important;
}

.icon-button-create-ann:hover{
    filter: opacity(100%) !important;
}

.level-type-container {
    display: flex;
    gap: 2.5px;
}

@media (max-width: 870px) {
    .show-announcement-main {
        flex-direction: column;
    }

    .show-announcement-imgs {
        width: 100%;
    }

    .show-announcement-info {
        margin-left: 0px;
        width: 100%;
        margin-top: 15px;
    }

    .show-announcement-info h2 {
        font-size: 45px;
        margin-top: 10px;
        margin-bottom: 7px;
        font-weight: 500;
    }
    

    .div-fav-btn::before {
        content: 'Añadir a favoritos';
        font-size: 12px;
        color: black;
        font-weight: 700;
        font-family: "Lexend Deca", sans-serif;
    }

    .quit-from-favs::before {
        content: 'Quitar de   favoritos';
    }

    .watch-as-a-client::before {
        content: 'Ver como comprador';
        font-size: 12px;
        color: black;
        font-weight: 700;
        font-family: "Lexend Deca", sans-serif;
    }

    .share::before {
        content: 'Compartir';
        font-size: 12px;
        color: black;
        font-weight: 700;
        font-family: "Lexend Deca", sans-serif;
    }

    .caracteristicas-annoucement {
        grid-template-columns: 1fr 1fr; 
    }

    .ann-seller-section {
        width: 100%;
        display: grid; 
        grid-auto-columns: 1fr; 
        grid-template-columns: 0.5fr 1.5fr; 
        grid-template-rows: 1fr 0.1fr; 
        gap: 0px 5px; 
        grid-template-areas: 
          "foto info"
          "btns btns"; 
    }

    .ann-seller-section-btns{
        flex-direction: column;
    }

    .ann-seller-section-btn {
        width: 100% !important;
    }

    .section-show-review-profile-pic {
        min-width: 60px;
        width: 60px;
        max-width: 60px;
    }

    .section-show-review-info {
        width: calc(100% - 60px - 7px - 30px);
        margin-left: 7px;
    }

    .section-show-review-buttons {
        width: 30px !important;
    }

    .swal-contact-seller-popup {
        width: 100%;
    }

    .a-show-contact-container, .contact-seller-popup p {
        width: 100%;
    }

    .show-annoucement-general-btns {
        flex-direction: column-reverse;
    }

    .a-show-contact-button {
        width: 100%;
    }
}
